import { Button, Col, Form, Input, InputNumber, Row, Select, Skeleton } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import FloatLabel from "src/components/Form/FloatLabel";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { reportSelector } from "src/state/report/report.reducer";
import { trimObject } from "src/utils/helperFunction";
import { IFilterData } from "./Counsellor.model";
import { packageSelector } from "src/state/package/package.reducer";
import { IPackageRecord } from "../Package/Packages.model";
import { searchPackageData } from "src/state/package/package.action";
import { searchBranchData } from "src/state/branch/branch.action";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchSubCourseData } from "src/state/subCourse/subCourse.action";
import { searchCourseData } from "src/state/course/course.action";
import { subcourseSelector } from "src/state/subCourse/subCourse.reducer";
import { ISubCourseDetails } from "src/services/subCourse/subCourse.model";
import { searchCounsellorData } from "src/state/report/report.action";
import { ICounsellorDetailsRowData } from "src/services/report/report.model";
import { tagSelector } from "src/state/tags/tags.reducer";
import { getTags } from "src/state/tags/tags.action";
import { ColumnType } from "antd/lib/table";
import TableComponent from "src/components/DataTable";
import { CounsellerCard } from "./CounsellerCard";
import { debounce } from "lodash";

const Counsellor = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const reportState = useAppSelector(reportSelector);
  const tagState = useAppSelector(tagSelector);
  const branchState = useAppSelector(branchSelector);
  const subCourseState = useAppSelector(subcourseSelector);
  const packageState = useAppSelector(packageSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [dynamicPackage, setDynamicPackage] = useState<IPackageRecord[]>([]);
  const { Option } = Select;
  const allZoneCheck = useMemo(() => searchParams.get("allZoneCheck") === "true", [searchParams])

  const filterDataConvertFromSearchParam = () => {
    const filterData: IFilterData = {};
    const splitFieldValue = ["branch_ids", "tag_ids"];
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;

      if (value !== "") {
        if (splitFieldValue?.includes(key)) {
          Object.assign(filterData, {
            [key]: Array.from(value.split(","), Number),
          });
        } else if (key === "eligibility") {
          Object.assign(filterData, {
            [key]: value.trim(),
          });
        } else if (key === "allZoneCheck") {
          if (value === "true") {
            Object.assign(filterData, {
              [key]: true,
            });
          }
        } else {
          Object.assign(filterData, {
            [key]: +value,
          });
        }
      }
    }

    form.setFieldsValue({ ...filterData });

    return filterData;
  };

  const fetchCounsellorDataHandler = (filterData: IFilterData) => {
    dispatch(searchCounsellorData(filterData))?.then((res) => {
      if (res?.payload) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const filterData = filterDataConvertFromSearchParam();
    fetchCounsellorDataHandler(filterData);
  }, [searchParams]);

  useEffect(() => {
    dispatch(getTags());

    dispatch(
      searchPackageData({ noLimit: true, orderBy: "name", order: "ASC" })
    );
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isAllBranch: true,
      })
    );

    dispatch(
      searchCourseData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );

    dispatch(
      searchSubCourseData({ noLimit: true, orderBy: "name", order: "ASC" })
    );
  }, []);

  useEffect(() => {
    if (
      branchState?.branchesData?.data?.rows &&
      packageState?.packageData?.data?.rows
    ) {
      const branchIds = branchState?.branchesData?.data?.rows?.map(
        (branchDetails) => +branchDetails.id
      );
      const packagesData = packageState?.packageData?.data?.rows;

      const packagesOnBranch =
        packagesData.filter((packageDetails) => {
          return (
            packageDetails &&
            packageDetails?.package_branches?.find((packageBranchDetails) =>
              branchIds?.includes(packageBranchDetails?.branch_id)
            )
          );
        }) || [];
      setDynamicPackage(packagesOnBranch as IPackageRecord[]);
    }
  }, [
    packageState?.packageData?.data?.rows,
    branchState?.branchesData?.data?.rows,
  ]);

  const columns: ColumnType<object>[] = [
    {
      title: "",
      align: "center",
      width: "99%",
      render: (record: ICounsellorDetailsRowData) => {
        return <CounsellerCard counsellerData={record} />;
      },
    },
  ];

  const onChangeFilter = debounce((values: IFilterData) => {
    const filterData = form.getFieldsValue();

    const filterFields = Object.entries(
      trimObject({ ...filterData, ...values })
    )
      .filter(([, values]) => values !== undefined && values !== "")
      ?.filter(([, values]) =>
        Array.isArray(values) ? values?.length > 0 : values
      );

    const queryString = filterFields
      .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
      .join("&");

      setSearchParams(queryString);
  }, 300);

  const onReset = () => {
    form.resetFields();
    Promise.all([setSearchParams({})]);
  };

  return (
    <>
      <div className="rnw-main-content counsellor-page">
        <Skeleton active loading={loading} avatar>
          <ContainerHeader
            title={`Course Information (${
              reportState?.counsellorDetailsData?.data.length || 0
            })`}
          />
          <Form
            id="myForm"
            className="gx-mt-2"
            onValuesChange={onChangeFilter}
            form={form}
            onReset={onReset}
            initialValues={{ isZone: true }}
          >
            <Row gutter={24}>
              <Col xl={4} lg={8} md={12} xs={24}>
                <FloatLabel
                  label="Select Branch Code"
                  placeholder="Select Branch Code"
                  name="branch_ids"
                >
                  <Form.Item name="branch_ids">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      className="dash_input"
                      showSearch
                      showArrow
                      mode="multiple"
                      size="large"
                      filterOption={(value, option) =>
                        (option?.children?.toString() || "")
                          .toLowerCase()
                          .includes(value.toLowerCase().trim())
                      }
                    >
                      {(branchState.branchesData.data.rows || [])
                        .filter((branch) => branch.status === true)
                        .map((branch) => (
                          <Option value={branch.id}>{branch.code}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </FloatLabel>
              </Col>
              <Col xl={4} lg={8} md={12} xs={24}>
                <FloatLabel
                  label="Suggestive Word"
                  placeholder="Select Suggestive Word"
                  name="tag_ids"
                >
                  <Form.Item name="tag_ids">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      size="large"
                      allowClear
                      showArrow
                      mode="multiple"
                      filterOption={(input, option) =>
                        (option?.children?.toString() || "")
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                    >
                      {tagState?.getTag?.data?.map((tag) => (
                        <Option value={tag.id}>{tag.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FloatLabel>
              </Col>

              <Col xl={4} lg={8} md={12} xs={24}>
                <FloatLabel
                  label="Eligibility"
                  placeholder="Enter Eligibility"
                  name="eligibility"
                >
                  <Form.Item name="eligibility">
                    <Input size="large" />
                  </Form.Item>
                </FloatLabel>
              </Col>

              <Col xl={4} lg={8} md={12} xs={24}>
                <FloatLabel
                  label="Package"
                  placeholder="Select Package"
                  name="package_id"
                >
                  <Form.Item name="package_id">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.children?.toString() || "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {dynamicPackage?.map((packageDetails) => (
                        <Option
                          value={packageDetails?.id && packageDetails?.id}
                        >
                          {packageDetails.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FloatLabel>
              </Col>

              <Col xl={4} lg={8} md={12} xs={24}>
                <FloatLabel
                  label="Sub Course"
                  placeholder="Select Sub Course"
                  name="subcourse_id"
                >
                  <Form.Item name="subcourse_id">
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      size="large"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.children?.toString() || "")
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                    >
                      {subCourseState.searchData?.data?.rows?.map(
                        (subCourse: ISubCourseDetails) => (
                          <Option value={subCourse.id}>{subCourse.name}</Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </FloatLabel>
              </Col>              
            </Row>
          </Form>

          <Row gutter={24} className="mb-20 erp-button-group">
            <Col>
              <Button
                htmlType="button"
                className={`bg-danger gx-mb-2 ${
                  allZoneCheck ? "active" : ""
                }`}
                onClick={() => {
                  const updatedParams = new URLSearchParams(
                    searchParams.toString()
                  );
                  updatedParams.set("allZoneCheck", "true");
                  setSearchParams(updatedParams.toString());
                }}
              >
                All
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="button"
                className={`bg-danger gx-mb-2 ${allZoneCheck ? "" : "active"}`}
                onClick={() => {
                  const updatedParams = new URLSearchParams(
                    searchParams.toString()
                  );
                  updatedParams.set("allZoneCheck", "false");
                  setSearchParams(updatedParams.toString());
                }}
              >
                Branch
              </Button>
            </Col>
          </Row>

          <Row align="middle" justify="space-between" gutter={24}>
            <Col sm={24} className="view-addmission gx-px-0">
              <TableComponent
                dataSource={reportState?.counsellorDetailsData?.data || []}
                columns={columns}
                loading={loading}
              />
            </Col>
          </Row>
        </Skeleton>
      </div>
    </>
  );
};

export default Counsellor;
